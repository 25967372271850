//  When I wrote this code, only God and I knew what it was. Now, only God knows!
<!--
 * @Description:
 * @Author: yjp
 * @Date: 2020-03-28
 * @LastEditTime: 2020-05-07 14:46:12
 * @FilePath: /src/pages/index/views/orderList/contract/contractApply.vue
 -->
<template>
  <div>
    <tableApply
      :apply-list-prop="{type: 'HT'}"
      @subForm="subForm"
    />
  </div>
</template>

<script>
import tableApply from '../components/tableApply';

export default {
  components: {
    tableApply,
  },
  props: {},
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  updated() {
  },
  methods: {
    // 子组件显示表单 invoiceForm
    subForm(type, obj) {
      this.$router.push({
        path: '/order/contract/contractForm',
        name: 'contractFormPage',
        params: {
          ...obj,
        },
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
